.navbar {
    min-height: 80px;
}

.navbar-brand {
    padding: 0 15px;
    line-height: 60px;
}

.carouselUnder {
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
}

.holder {
    padding: 20px;
}

.warn {
    color: red;
}
.center {
    text-align: center;
}

.refundwarn {
    font-size: xx-large;
    color: red;
  }
  

.full {
    width: 100%;
}

.subBorder {
    padding-bottom: 30px;
}


.carousel-indicators {
    display: flex;
    justify-content: center;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 1201px) {
    .carouselSubtitle {
        font-family: "PT Serif", sans-serif;
        color: white;
        font-size: 1.5vw;
    }

    .carouselTitle {
        font-family: "PT Serif", sans-serif;
        color: white;
        font-size: 2.5vw;
    }

}

@media screen and (max-width: 1200px) {
    .carouselSubtitle {
        font-family: "PT Serif", sans-serif;
        color: white;
        font-size: 2vw;
    }

    .carouselTitle {
        font-family: "PT Serif", sans-serif;
        color: white;
        font-size: 3vw;
    }
}

@media screen and (min-width: 780px) {
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }
    .carousel-control-next {
        width:30%
    }
    .carousel-control-prev {
        width:30%
    }
}

@media screen and (max-width: 780px) {
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }
}

.homeDescription {
    font-size: 18px;
}

