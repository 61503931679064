.react-card {
  margin-top: 20px;
}

.error {
  color: #a94442;
}

.center {
  text-align: center;
}

.centerImage {
  display: block;
  margin: auto;
}

.holder {
  padding-left: 20px;
  padding-right: 20px;
}