.App {
  margin-top: 15px;
}

.AppContainer
{
  padding: 25px;
}
.navbar {
  min-height: 40px;
}

.navbar-brand {
  height: 100px;
  font-weight: bold;
}

.active.nav-item {
  font-weight: bold !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 1.25rem;
}