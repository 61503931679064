body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.holder {
  padding: 20px;
}

a {
  color: #08c;
}

code {
  background: #eee;
  padding: .1rem;
  font-family: 'Menlo';
  font-size: 13px;
  color: #ff00aa;
}

.refundwarn {
  font-size: x-large;
  color: red;
}


input {
  padding: .5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input[type="checkbox"],
input[type="radio"],
input[type="checkbox"]+label,
input[type="radio"]+label {
  display: inline-block;
  width: auto;
  padding-left: 6px;
}


input.error {
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
  font-size: 14px;
}

button {
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}

button+button {
  margin-left: .5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.animated {
  animation-duration: .3s;
  animation-fill-mode: both;
}

legend {
  display: block;
  font-size: 16px;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
}


.custom-modal {
  width: 90%;
  max-width: none !important;
}

.DayPicker-Day {
  width: 38px !important;
}

.lessonTimes {
  padding: 4px 20px;
}